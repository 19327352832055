:root {
  --grey: #494a4e;
}

* {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  color: var(--grey);
  text-align: center;
}

h1 {
  font-weight: 300;
  text-transform: uppercase;
  margin: 0;
}

h2 {
  text-transform: uppercase;
  font-weight: 500;
}

span.heading {
  display: block;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: .2em;
  margin-top: 5px;
  margin-bottom: 10px;
}

ul {
  width: 50%;
  margin: auto;
}

li {
  line-height: 22px;
  text-align: left;
}

h3 {
  text-transform: uppercase;
  font-weight: 400;
}

p {
  margin-top: 10px;
  font-size: .9em;

  color: #a0a0a0;
}

b {
  font-weight: 500;

  color: #a0a0a0;
}

body {
  background-image: url(img/BG.webp);
  background-repeat: no-repeat;
  background-size: cover;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@font-face {
  font-family: "Montserrat";
  src: url(fonts/Montserrat-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url(fonts/Montserrat-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url(fonts/Montserrat-LightItalic.ttf) format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: url(fonts/Montserrat-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url(fonts/Montserrat-MediumItalic.ttf) format("truetype");
  font-weight: 500;
  font-style: italic;
}

.padding {
  width: 80%;
  margin: auto;
  padding-top: 10%;

  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.grid {
  width: 70%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 70px;

  margin-top: 70px;
  margin-bottom: 70px;
}

.projects {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 30px;
}

.project {
  background-color: var(--grey);
  border-radius: 5px;

  padding: 20px;

  -webkit-box-shadow: 0px 9px 20px -6px #000000; 
  box-shadow: 0px 9px 20px -6px #000000;
}

.project p {
  color: #d3d3d3;
  font-weight: normal;
  margin: 0;
}

.project h4 {
  font-size: 1.1em;
  margin: 10px;
  font-weight: 500;
  color: #fff;
}

.project img {
  border-radius: 5px;
}

.footer {
  margin: 40px;
}

.footer a {
  text-decoration: none;
  font-weight: 500;
  color: #a0a0a0;

  transition: all .4s ease-in-out;
}

.footer a:hover {
  font-style: italic;
}

.icons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.icon {
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background-color: #a0a0a0;

  margin-right: 7px;

  position: relative;
  transition: all .2s ease-in-out;
}

.icon img {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  width: 65%;
  height: auto;  
}

.icon:hover {
  background-color: var(--grey);
} 

@media only screen and (max-width: 900px) {
  .projects {
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
  }

  .grid {
    display: block;
  }

  p {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  ul {
    width: 100%;
  }
}